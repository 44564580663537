import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  TextInput,
  //ImageField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
// import VendorShow from './VendorShow';
// import SimpleChipField from './SimpleChipField';

// const [vendors, setVendors] = useState([]);

// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const response = await VendorsDataProvider();
//       setVendors(response.data);
//       console.log(vendors);
//     } catch (error) {
//       console.error('Error fetching vendors:', error.message);
//     }
//   };

//   fetchData();
// }, []);
const VendorFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    {/* <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn /> */}
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Vendor Type" source="type" />
    <TextInput label="Vendor Name" source="vendorName" alwaysOn />
    <TextInput label="Vendor Id" source="vendorId" alwaysOn />
    <TextInput label="Customer Id" source="userId" />
    <TextInput label="Profession" source="profession" />
    <TextInput label="Qualification" source="qualification" />
    <TextInput label="Current Status" source="currentStatus" alwaysOn />
    <TextInput label="Working Status" source="workingStatus" alwaysOn />
    <TextInput label="Manpower Status" source="manpowerStatus" />
    <TextInput label="Rental Status" source="rentalStatus" />
    <TextInput label="Project Status" source="projectStatus" />
    <TextInput label="Training Status" source="trainerStatus" />
  </Filter>
);
const role = localStorage.getItem('role');
console.log('~~~~~~~~~~~', role);
const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  if (role == 'admin') return 'edit';
};

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton disabled={role != 'admin'} basePath={basePath} record={record} />;
};

const VendorBulkActionButtons = (props) => <BulkDeleteWithConfirmButton disabled={role != 'admin'} {...props} />;
console.log(VendorBulkActionButtons);
const styles = {
  image: {
    maxHeight: '70px',
    maxWidth: '70px',
    borderRadius: '30%'
  }
};

const VendorList = ({...props}) => (
  <List title=" Vendors" {...props} filters={<VendorFilter />} bulkActionButtons={<VendorBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<VendorShow />}*/ {...props}>
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="vendorName" />
      <TextField source="userId" label="Customer Id" />
      <TextField source="serviceName" />
      <TextField source="phone" />
      <EmailField source="email" />
      <ArrayField source="profession">
        <SingleFieldList linkType={false}>
          <ChipField source="key" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="qualification" />
      <TextField source="type" label="Vendor Type" />
      <TextField source="currentStatus" />
      <TextField source="workingStatus" />
      <TextField source="manpowerStatus" />
      <TextField source="rentalStatus" />
      <TextField source="projectStatus" />
      <TextField source="trainerStatus" />
      <TextField source="createdAt" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default withStyles(styles)(VendorList);
